export const SITE_NAME = `Kite School of Kenpo`
export const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL || `https://www.kiteschoolofkenpo.co.uk`
export const SITE_DOMAIN = `kiteschoolofkenpo.co.uk`
export const CONTACT_EMAIL = `info@kiteschoolofkenpo.co.uk`
export const CONTACT_PHONE = `+447534 965363`
export const WHAT3WORDSADDRESS = '///debit.remove.issued'
export const WHAT3WORDSLINK = 'https://w3w.co/debit.remove.issued'
export const ADDRESS = `Kite School of Kenpo, Paston Church Hall, Bartram Gate, Paston, Peterborough, PE4 7YQ`
export const FOOTER_ADDRESS = `Kite School of Kenpo<br />
Paston Church Hall<br />
Bartram Gate<br />
Peterborough<br />
PE4 7YQ`
export const STREET_ADDRESS = 'Paston Church Hall, Paston Church Hall'
export const POSTCODE = 'PE4 7YQ'

export const FACEBOOK_LINK = `https://www.facebook.com/KiteSchoolofKenpo/`
export const BUNNY_CDN_IFRAME = `https://iframe.mediadelivery.net/embed/:libraryId/:videoId?autoplay=true`
export const BUNNY_CDN_PULLZONE_NAME = process.env.BUNNY_CDN_PULLZONE_NAME || `vz-903db3a2-7f8`
export const BUNNY_CDN_TOKEN_AUTH_KEY = process.env.NEXT_PUBLIC_BUNNY_CDN_TOKEN_AUTH_KEY || ''

export const RED_KITE_URL = 'https://redkitekenpo.co.uk'
export const BLACK_KITE_URL = 'https://blackkitekenpo.co.uk'
export const KITE_SCHOOL_OF_KENPO_COM = 'https://www.kiteschoolofkenpo.com'

export const GET_MOTIVATED = 'get-motivated'
export const ABOUT_KENPO_KARATE = 'about-kenpo-karate'
export const MAP_COORDINATES = { lat: 52.60569015094963, lng: -0.2562014921461072 }
export const LATITIUDE = '52.60569015094963'
export const LONGITUDE = '-0.2562014921461072'

export const VIDEO_GUIDES = "/members/video-guides"
export const CHOOSE_YOUR_PLAN = "/account/choose-your-plan"