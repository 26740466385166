import type { Tables } from "@/types_db";
import { SITE_NAME } from "@/utils/constants";
import imageUrlBuilder from "@sanity/image-url";
import { client } from "@/lib/sanity.client";

type Price = Tables<"prices">;

const builder = imageUrlBuilder(client);

export const urlFor = source => {
  return builder.image(source);
};

export const getPostUrl = (slug, type = "about-kenpo-karate") => {
  return getURL() + "/" + type + "/" + slug;
};

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const dateTimeFormat = new Intl.DateTimeFormat("en-GB");

export const getURL = (path: string = "") => {
  // Check if NEXT_PUBLIC_SITE_URL is set and non-empty. Set this to your site URL in production env.
  let url =
    process?.env?.NEXT_PUBLIC_SITE_URL &&
    process.env.NEXT_PUBLIC_SITE_URL.trim() !== ""
      ? process.env.NEXT_PUBLIC_SITE_URL
      : // If not set, check for NEXT_PUBLIC_VERCEL_URL, which is automatically set by Vercel.
      process?.env?.NEXT_PUBLIC_VERCEL_URL &&
      process.env.NEXT_PUBLIC_VERCEL_URL.trim() !== ""
        ? process.env.NEXT_PUBLIC_VERCEL_URL
        : // If neither is set, default to localhost for local development.
        "https://www.kiteschoolofkenpo.co.uk";

  // Trim the URL and remove trailing slash if exists.
  url = url.replace(/\/+$/, "");
  // Make sure to include `https://` when not localhost.
  url = url.includes("http") ? url : `https://${url}`;
  // Ensure path starts without a slash to avoid double slashes in the final URL.
  path = path.replace(/^\/+/, "");

  // Concatenate the URL and the path.
  return path ? `${url}/${path}` : url;
};

export const postData = async ({
                                 url,
                                 data,
                               }: {
  url: string;
  data?: { price: Price };
}) => {
  const res = await fetch(url, {
    method: "POST",
    headers: new Headers({ "Content-Type": "application/json" }),
    credentials: "same-origin",
    body: JSON.stringify(data),
  });

  return res.json();
};

export const toDateTime = (secs: number) => {
  let t = new Date(+0); // Unix epoch start.
  t.setSeconds(secs);
  return t;
};

export const calculateTrialEndUnixTimestamp = (
  trialPeriodDays: number | null | undefined,
) => {
  // Check if trialPeriodDays is null, undefined, or less than 2 days
  if (
    trialPeriodDays === null ||
    trialPeriodDays === undefined ||
    trialPeriodDays < 2
  ) {
    return undefined;
  }

  const currentDate = new Date(); // Current date and time
  const trialEnd = new Date(
    currentDate.getTime() + (trialPeriodDays + 1) * 24 * 60 * 60 * 1000,
  ); // Add trial days
  return Math.floor(trialEnd.getTime() / 1000); // Convert to Unix timestamp in seconds
};

const toastKeyMap: { [key: string]: string[] } = {
  status: ["status", "status_description"],
  error: ["error", "error_description"],
};

const getToastRedirect = (
  path: string,
  toastType: string,
  toastName: string,
  toastDescription: string = "",
  disableButton: boolean = false,
  arbitraryParams: string = "",
): string => {
  const [nameKey, descriptionKey] = toastKeyMap[toastType];

  let redirectPath = `${path}?${nameKey}=${encodeURIComponent(toastName)}`;

  if (toastDescription) {
    redirectPath += `&${descriptionKey}=${encodeURIComponent(toastDescription)}`;
  }

  if (disableButton) {
    redirectPath += `&disable_button=true`;
  }

  if (arbitraryParams) {
    redirectPath += `&${arbitraryParams}`;
  }

  return redirectPath;
};

export const getStatusRedirect = (
  path: string,
  statusName: string,
  statusDescription: string = "",
  disableButton: boolean = false,
  arbitraryParams: string = "",
) =>
  getToastRedirect(
    path,
    "status",
    statusName,
    statusDescription,
    disableButton,
    arbitraryParams,
  );

export const getErrorRedirect = (
  path: string,
  errorName: string,
  errorDescription: string = "",
  disableButton: boolean = false,
  arbitraryParams: string = "",
) =>
  getToastRedirect(
    path,
    "error",
    errorName,
    errorDescription,
    disableButton,
    arbitraryParams,
  );

export const getPostMetadata = (post, postUrl) => {
  return {
    title: `${post.title} - ${SITE_NAME}`,
    description: `${post.excerpt}`,
    alternates: {
      canonical: postUrl,
    },
    openGraph: {
      type: "article",
      title: `${post.title} - ${SITE_NAME}`,
      siteName: `${post.title} - ${SITE_NAME}`,
      description: `${post.excerpt}`,
      images: [
        {
          url: `${process.env.NEXT_PUBLIC_SITE_URL}/api/og?title=${post.title} - ${SITE_NAME}`,
          width: 1200,
          height: 630,
          alt: `${post.title} - ${SITE_NAME}`,
        },
      ],
    },
    twitter: {
      card: "summary_large_image",
      site: "@kiteschoolofkenpo",
      title: `${post.title} - ${SITE_NAME}`,
      description: `${post.excerpt}`,
      images: [
        {
          url: `${process.env.NEXT_PUBLIC_SITE_URL}/api/og?title=${post.title} - ${SITE_NAME}`,
          alt: `${post.title} - ${SITE_NAME}`,
        },
      ],
    },
  };
};