"use client";
import React, { useEffect } from "react";
import Head from "next/head";
import { Footer } from "@/components/layout/Footer";
import { Navigation } from "@/components/layout/Navigation";

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", () => {
        navigator.serviceWorker.register("/sw.js").then(
          (registration) => {
            console.log("Service Worker registration successful with scope: ", registration.scope);
          },
          (err) => {
            console.log("Service Worker registration failed: ", err);
          },
        );
      });
    }
  }, []);

  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <link href="/favicon.ico" rel="shortcut icon" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <a href="#content" className="sr-only focus:not-sr-only">
        Skip to content
      </a>
      <Navigation />
      <main id="content">
        {children}
      </main>
      <Footer />
    </>
  );
};

export default Layout;
