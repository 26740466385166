import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Popover, PopoverButton, PopoverGroup, PopoverPanel, Transition, useClose } from "@headlessui/react";
import Link from "next/link";
import { classNames, getPostUrl } from "@/utils/helpers";
import Logo from "@/components/layout/Logo";
import { Bars3Icon, ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { groq } from "next-sanity";
import { client } from "@/lib/sanity.client";
import { SignedIn, SignedOut, SignInButton, UserButton } from "@clerk/nextjs";
import { VIDEO_GUIDES } from "@/utils/constants";

const about = [
  {
    name: "The Origins of Kenpo Karate",
    description: "Kenpo is a style of Martial Arts which was developed to be street effective. Here you will learn how it evolved to be so effective.",
    href: "/about-kenpo-karate/the-origins-of-kenpo-karate",
    prefetch: false,
  },
  {
    name: "Belt Ranks and Themes",
    description: "Gradings in Kenpo Karate can be difficult, here you will learn what each belt signifies and what you will learn.",
    href: "/about-kenpo-karate/belt-ranks-themes",
    prefetch: false,
  },
  {
    name: "Kenpo Karate Syllabus",
    description: "There are over 120 techniques in Kenpo Karate. Here you will find what each of those are and what the attack is.",
    href: "/about-kenpo-karate/kenpo-karate-syllabus",
    prefetch: false,
  },
];

const indexQuery = groq`
*[_type=='post' 
  && dateTime(publishedAt) < dateTime(now())
  && 'get-motivated' in categories[]->slug.current
 ]{
  title,
  slug,
  categories[]->{name,slug}
}[0..1] | order(publishedAt desc)
`;

type Post = {
  _key: string;
  title: string;
  slug: { current: string };
  categories: { name: string; slug: { current: string } }[];
};

export const Navigation: React.FC = () => {
  let close = useClose();
  const [recentPosts, setRecentPosts] = useState<Post[]>([]);

  useEffect(() => {
    getPostsForNav().then((recentPosts) => {
      setRecentPosts(recentPosts);
    });
  }, []);

  const aboutLinks = useMemo(() => {
    return about.map((item) => (
      <Link
        onClick={() => close()}
        href={item.href}
        prefetch={item.prefetch}
        key={item.name}
        className="-m-3 p-3 flex items-start rounded-lg"
      >
        <div className="ml-4">
          <p className=" text-smlg:text-base font-medium text-white">{item.name}</p>
          <p className="mt-1 text-sm text-white">{item.description}</p>
        </div>
      </Link>
    ));
  }, [close]);

  const recentPostsLinks = useMemo(() => {
    return recentPosts.map((item: Post) => (
      <div key={item._key} className="flow-root mb-4">
        <Link
          onClick={() => close()}
          prefetch={false}
          href={getPostUrl(item.slug.current, item.categories[0].slug?.current)}
          className="-m-3 p-3 flex items-center rounded-md text-sm lg:text-base font-medium text-white"
        >
          <div className="ml-4">
            <p className=" text-smlg:text-base font-medium text-white">{item.title}</p>
          </div>
        </Link>
      </div>
    ));
  }, [recentPosts, close]);

  return (
    <Popover className="relative bg-dark-brand uppercase text-sm z-30">
      <div className="max-w-7xl mx-auto md:px-4 px-6">
        <div className="flex justify-between items-center pt-2 pb-2 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link href="/" title="Home" className="cursor-pointer">
              <Logo height={110} width={110} />
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <PopoverButton
              className="bg-dark-brand rounded-md p-2 inline-flex items-center justify-center text-white hover:text-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-dark-brand">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </PopoverButton>
          </div>
          <PopoverGroup as="nav" className="hidden md:flex space-x-8">
            <Link href="/" className="p-2 text-sm lg:text-base font-medium text-white hover:text-red-brand">
              Home
            </Link>
            <Link href="/about-kenpo-karate"
                  className="p-2 text-sm lg:text-base font-medium text-white hover:text-red-brand">
              About Us
            </Link>
            <Popover className="relative">
              {({ open }) => (
                <>
                  <PopoverButton
                    className={classNames(open ? "text-white" : "text-white", "p-2 group bg-dark-brand rounded-md inline-flex items-center text-sm lg:text-base font-medium hover:text-red-brand focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-brand-dark")}>
                    <span className="uppercase">About Kenpo Karate</span>
                    <ChevronDownIcon
                      className={classNames(open ? "text-gray-700" : "text-gray-400", "ml-2 h-5 w-5 group-hover:text-white")}
                      aria-hidden="true" />
                  </PopoverButton>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <PopoverPanel
                      className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2 normal-case">
                      {({ close }) => (
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <div onClick={() => close()} className="relative grid gap-6 bg-dark-brand px-5 py-6 sm:gap-8 sm:p-8">
                            {aboutLinks}
                          </div>
                          {recentPosts.length > 0 && <hr />}
                          <div className="px-5 py-5 bg-dark-brand space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                            <div className="flex flex-col">
                              <Link onClick={() => close()} href="/get-motivated"
                                    className="-m-3 p-3 pb-6 flex items-center rounded-md text-sm lg:text-base font-medium text-white">
                                Get Motivated
                              </Link>
                              <div onClick={() => close()}>
                                {recentPostsLinks}
                              </div>

                            </div>
                          </div>
                        </div>
                      )}
                    </PopoverPanel>
                  </Transition>
                </>
              )}
            </Popover>
            <Link href="/contact-us" className="p-2 text-sm lg:text-base font-medium text-white hover:text-red-brand">
              Contact Us
            </Link>
          </PopoverGroup>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <SignedIn>
              <Link href="/account"
                    className="md:mx-4 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-sm lg:text-base font-medium text-white bg-red-brand focus:ring-bg-red-brand-dark hover:bg-red-brand-dark hover:border-red-brand-dark border-red-brand hover:text-white hover:no-underline">
                Account
              </Link>
              <div className="ml-2">
                <UserButton />
              </div>
            </SignedIn>
            <SignedOut>
              <SignInButton
                mode="modal"
                forceRedirectUrl="/account"
              >
                <button
                  className={"m-1 uppercase whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-sm lg:text-base font-medium text-white bg-red-brand focus:ring-bg-red-brand-dark hover:bg-red-brand-dark hover:border-red-brand-dark border-red-brand hover:text-white hover:no-underline"}>
                  Sign in
                </button>
              </SignInButton>
            </SignedOut>
          </div>
        </div>
      </div>

      <Transition as={Fragment} enter="duration-200 ease-out" enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100" leave="duration-100 ease-in" leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95">
        <PopoverPanel focus
                      className="z-10 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          {({ close }) => (
            <div
              className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-dark-brand divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <Link className="cursor-pointer" href="/" title="Home">
                      <Logo height={100} width={100} />
                    </Link>
                  </div>
                  <div className="-mr-2">
                    <PopoverButton
                      className="bg-dark-brand rounded-md p-2 inline-flex items-center justify-center text-white hover:text-red-brand hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-brand-dark">
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </PopoverButton>
                  </div>
                </div>
              </div>
              <div className="pt-6 pb-6 px-5 space-y-6">
                <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                  <Link href="/" className="p-2 text-sm lg:text-base font-medium text-white hover:text-red-brand">
                    Home
                  </Link>
                  <Link href="/about-kenpo-karate"
                        className="p-2 text-sm lg:text-base font-medium text-white hover:text-red-brand">
                    About Us
                  </Link>
                  {about.map((item) => (
                    <Link key={item.name} href={item.href} prefetch={item.prefetch}
                          className="p-2 text-sm lg:text-base font-medium text-white hover:text-red-brand">
                      {item.name}
                    </Link>
                  ))}
                  <Link href="/contact-us"
                        className="p-2 text-sm lg:text-base font-medium text-white hover:text-red-brand">
                    Contact Us
                  </Link>
                  <Link href="/account"
                        className="p-2 text-sm lg:text-base font-medium text-white hover:text-red-brand">
                    Account
                  </Link>
                  <Link href={VIDEO_GUIDES}
                        className="p-2 text-sm lg:text-base font-medium text-white hover:text-red-brand">
                    Video Guides
                  </Link>
                </div>
              </div>
              <div className="pt-6 pb-6 px-5 space-y-6">
                <div className="flex items-center justify-center md:hidden">
                  <SignedIn>
                    <UserButton />
                  </SignedIn>
                  <SignedOut>
                    <SignInButton mode="modal" />
                  </SignedOut>
                </div>
              </div>
            </div>
          )}
        </PopoverPanel>
      </Transition>
    </Popover>
  );
};

async function getPostsForNav(): Promise<Post[]> {
  return await client.fetch(indexQuery);
}
